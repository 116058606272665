body {
  > .content {
    @extend .container-fluid;
    margin: 10px auto;
    margin-bottom: 0;
    min-height: calc(100vh);
    padding: 0;

    @include media-breakpoint-up(sm) {
      padding: 0;
    }

    img {
      @extend .img-fluid;
    }

    ul {
      padding-left: 22px;
    }

    table {
      @extend .table;
    }
  }
}

strong {
    font-weight: $font-weight-medium;
}

h1 {
  color: $color-petrol-dark;
  font-size: $font-size-h1;
  font-weight: $font-weight-normal;
  line-height: 47px;
  margin: 2.3rem 0;

  @include media-breakpoint-down(md) {
    font-size: calc(#{$font-size-h1} - 5px);
    line-height: 38px;
  }

  @include media-breakpoint-down(sm) {
    font-size: calc(#{$font-size-h1} - 10px);
  }
}

h2 {
  color: $color-petrol-dark;
  font-size: $font-size-h2;
  line-height: 47px;
  font-weight: $font-weight-normal;
  margin: 2.3rem 0;

  @include media-breakpoint-down(md) {
    font-size: calc(#{$font-size-h2} - 5px);
    line-height: 38px;
  }


  @include media-breakpoint-down(sm) {
    font-size: calc(#{$font-size-h2} - 10px);
  }
}

h3 {
  font-size: $font-size-h3;
  font-weight: $font-weight-bold;
  color: $color-green;

  @include media-breakpoint-down(md) {
    font-size: calc(#{$font-size-h3} - 2px);
  }

  @include media-breakpoint-down(sm) {
    font-size: calc(#{$font-size-h3} - 4px);
  }
}

h4 {
    font-size: $font-size-h4;
    font-weight: $font-weight-medium;
    margin-bottom: 22px;

    @include media-breakpoint-down(md) {
        font-size: calc(#{$font-size-h4} - 2px);
    }
}

h5 {
    margin-top: 10px;
    color: $color-green;
    font-size: $font-size-base;
}

ul {
  li {
    list-style-type: square;

    a {
        color: $color-black;
    }
  }
}

.btn-brand-primary {
  border-radius: 0;
  border: 1px solid $brand-primary;
  background-color: transparent;
  color: $brand-primary;
  padding: 8px 15px;
  font-weight: $font-weight-light;
  text-transform: uppercase;
  font-weight: $font-weight-normal;
  letter-spacing: 0.5px;
  font-size: $font-size-base;

  @include media-breakpoint-up(sm) {
    padding: 8px 25px;
  }

  &:hover {
    text-decoration: none;
    background-color: $brand-primary;
    color: $white;
  }
}

.neos-contentcollection {
  @include clearfix;
}
