.grid-content.full {
    @extend .container-fluid;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;

    &.petrol {
        background-color: $brand-primary;
        padding: 40px 0 68px;

        h1, h2, p {
            color: $color-white;
        }
    }

    &.petrol-light {
        background-color: $color-petrol-light;
        padding: 40px 0 68px;
        overflow: hidden;

        h2 {
            font-weight: $font-weight-bold;
            color: $color-gray-medium;
        }
    }

    &.petrol-gray {
        background-color: $color-gray;
        padding: 40px 0 68px;
    }
    
    &.background-darker-gray {
        @include media-breakpoint-down(sm){
          padding: 35px 15px;
        }
        
        background-color: #cecdcd;
        padding: 30px;
    }
    
    &.mb00 {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .grid-content-container {
        @extend .container;
    }

    .grid-content-col {
        &.col-12 {
            // padding-left: 0;
            // padding-right: 0;

            // @include media-breakpoint-down(sm) {
            //     padding-left: 15px;
            //     padding-right: 15px;
            // }

            .neos-nodetypes-image {
                img {
                    width: 100%;
                }
            }
        }

        &.industry {
            .neos-contentcollection {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;

                img {
                    height: 70px;
                    justify-content: center;
                    display: flex;
                    margin: auto;
                }

                h3 {
                    font-weight: $font-weight-normal;
                    text-align: center;
                    color: $white;
                    font-size: $font-size-h2;
                }

                p {
                    text-align: center;
                }
            }
        }
    }

    &.banner {
        padding: 20px 0 9px;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.grid-content {
    @extend .container;
    padding: 40px 15px;

    .row {
        @include media-breakpoint-down(sm) {
            margin-right: -15px !important;
        }
    }

    &.tabNav {
        padding-bottom: 0 !important;

        @include media-breakpoint-down(md) {
            padding-bottom: 40px !important;
        }

        @include media-breakpoint-down(sm) {
            padding-left: 15px;
        }
    }

    &.news {
        padding-bottom: 0;

        ~.neos-contentcollection {
            .grid-content {
                padding-top: 0;
            }
        }
        
        .press-release--wrapper {
            @include make-col(10);
            
            @include media-breakpoint-down(md) {
                @include make-col(12);
            }
        }
    }
}

.grid-content-col {
    &.inner-well {
        > .neos-contentcollection {
            @extend .well;
        }
    }

    &.col-3 {
        @include media-breakpoint-down(md) {
            @include make-col(5);
        }
    }

    &.col-6, &.col-8, &.col-10 {
        @include media-breakpoint-down(md) {
            @include make-col(12);
        }
    }

    &.offset-1, &.offset-2 {
        @include media-breakpoint-down(md) {
            @include make-col-offset(0);
        }
    }

    &.margin-top-25 {
        margin-top: 25px;
    }

    &.margin-top-50 {
        margin-top: 50px;
    }

    &.margin-bottom-25 {
        margin-bottom: 25px;
    }

    &.margin-bottom-50 {
        margin-bottom: 50px;
    }

    &.petrol {
        color: $color-white;
    }

    &.centerPumps {
        @include make-col(12);

        .neos-contentcollection {
            display: flex;
            justify-content: center;
            align-self: center;
            width: 100%;

            @include media-breakpoint-down(sm) {
                flex-wrap: wrap;
            }

            .neos-nodetypes-image {
                &:first-child {
                    margin-right: 12%;
                }

                img {
                    height: 245px;
                    width: 100%;

                    @include media-breakpoint-down(lg) {
                        height: auto;
                    }
                }
            }
        }
    }

    &.companyLogos {
        .neos-nodetypes-company {
            margin-top: 50px;

            &:first-child {
                margin-top: 25px;
            }
        }
    }
}
