.neos-nodetypes-text {
    &.color-primary {
        color: $brand-primary;
    }

    p, ul {
        font-size: $font-size-base;
        font-weight: $font-weight-normal;
        
        li {
            a {
                color: $color-petrol;
                
                &:hover {
                    color: $link-hover-color;
                }
            }
        }

        @include media-breakpoint-down(md) {
            font-size: 18px;
        }
    }

    h3 {
        font-weight: $font-weight-bold;
        font-size: inherit;
    }
}

.neos-nodetypes-headline {
    &.likeh4 {
        h2, h3, h5 {
            @extend h4;
            color: $color-black;
        }
    }
}

.neos-nodetypes-text-with-image {
    @include make-col(3);
}

.neos-nodetypes-image {
    img {
        width: 100%;
    }

    figcaption {
        margin-top: 2px;

        p {
            font-style: italic;
            font-size: 1.2rem;
            font-weight: $font-weight-light;
            letter-spacing: 0.02rem;
            color: $color-petrol-dark;
        }
    }
}

.newsletter {
    .newsletters-headline {
        h2 {
            margin-top: 0;
            margin-bottom: 5px;
            text-align: left;

            font-family: Vialog LT W01 Light, Tahoma, Arial, sans-serif;
            font-style: normal;
            font-variant: normal;
            font-weight: 300;
            font-size: 30px;
            line-height: 35.2px;

            letter-spacing: 0.3px;
            color: #212529;

            strong {
                font-weight: 500;
            }
        }
        
        h3 {
            margin-top: 0;
            margin-bottom: 0px;
            text-align: left;

            font-family: Vialog LT W01 Light, Tahoma, Arial, sans-serif;
            font-style: normal;
            font-variant: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 25px;
            padding-top: 3px;

            letter-spacing: 0.4px;
            color: #464646;
        }
    }
    
    .neos-nodetypes-html {
        iframe {
            height: 160px;
            @media (max-width: 1145px) {
                height: 235px;
            }
            @media (max-width: 991px) {
                height: 255px;
            }
            @media (max-width: 882px) {
                height: 310px;
            }
            @media (max-width: 767px) {
                height: 340px;
            }
            @media (max-width: 510px) {
                height: 385px;
            }

            @media (max-width: 407px) {
                height: 410px;
            }
            
            button {
                background-color: red;
            }
        }
    }
}