.tab-collection--wrapper {
    .tab-content {
        position: relative;
        margin-top: 10px;

        .neos-contentcollection {

            >  .tab-pane {
                display: none;
            }

            .tab-pane {

                &.active {
                    display: block;
                }
            }
        }

        .grid-content-container {
            padding: 0;

        }

        .icon-bg {
            height: 170px;
            width: 200px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            position: absolute;
            left: -23%;
            top: 40px;
            filter: invert(6%) sepia(4%) saturate(348%) hue-rotate(119deg) brightness(96%) contrast(83%);

            @media (max-width: 1635px) {
                background-position-y: bottom;
                background-position-x: left;
                height: 25%;
                width: 25%;
                left: -6%;
                top: auto;
                bottom: -88px;
            }
        }
    }
    
    .tab-content-company {
        .neos-contentcollection {

            >  .tab-pane {
                display: none;
            }

            .tab-pane {

                &.active {
                    display: block;
                }
            }
        }
    }
}
