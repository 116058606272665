.carousel {
  margin: $line-height-base auto;

  .carousel-control {
    background-image: none;
  }

  .carousel-control-icon {
    background-repeat: no-repeat;
    background-size: 45px;
    transition: opacity .1s;
    opacity: 1;

    &:hover {
        .carousel-control-prev-icon  {
            transform: scale(1.1);
        }
    }

    &.left {
      background-image: url("../Images/layout/arrow-left.svg");
      background-position: 20% 48%;
    }

    &.right {
      background-image: url("../Images/layout/carousel-arrow-right.svg");
      background-position: 70% 48%;
    }
  }

  .carousel-control-prev, .carousel-control-next {
      width: auto;
  }

  .carousel-control-prev {
    left: 3%;

    .carousel-control-prev-icon {
        background-image: url("../Images/layout/arrow-left.svg");
        position: relative;
        height: 45px;
        width: 32px;
    }
  }

  .carousel-control-next {
    right: 3%;

    .carousel-control-next-icon {
        background-image: url("../Images/layout/arrow-right.svg");
        position: relative;
        height: 45px;
        width: 32px;
    }
  }
}
