.footer {
    padding: 50px 0;
    background-color: #54555a;
    color: white;
    font-weight: $font-weight-light;
    font-size: 19px;

    a {
        color: white;
        text-decoration: underline;
    }

    @include media-breakpoint-down(sm) {
        padding: 20px 0;
    }

    &.other {
        padding: 60px 0;

        .footer-about {
            @include make-col(8);

            @include media-breakpoint-down(sm) {
                @include make-col(12);
            }

            p {
                border-top: none;
            }
        }

        .footer-nav-list {
            margin-top: 0
        }
    }
}

.footer-content {
    @extend .container;

    .row {
        @include media-breakpoint-down(sm) {
            margin-left: 0;
            margin-right: 0;
        }
    }
}

.footer-logo-partner,
.footer-logo {
    display: flex;
    justify-content: flex-start;

    img {
        height: 30px;
        width: auto;
        margin-bottom: 20px;
    }
}

.footer-logo-partner {
    &:first-child {
        img {
            width: auto;
            background-color: white;
        }
    }
}

.footer-logo {
    img {
        max-height: 50px;
        margin-bottom: 20px;
    }
}

.footer-about {
    @include make-col(12);
    font-size: 19px;

    @include media-breakpoint-up(sm) {
        @include make-col(4);
        padding-left: 30px;

        &:first-child {
            padding-left: 0;
        }
    }

    @include media-breakpoint-down(sm) {
        margin-bottom: 28px;
    }

    p {
        border-top: 1px solid $color-gray;
        padding-top: 15px;
    }

    .bi {
        margin-top: -2px;
        margin-right: 5px;
    }
}

.footer-nav {
    display: flex;
    justify-content: flex-end;

    @include media-breakpoint-up(sm) {
        @include make-col(3);
        @include make-col-offset(1);
    }

    a {
        color: inherit;

        &:hover {
            color: darken($text-color, 15%);
        }
    }
}

.footer-nav-list {
    padding: 0;
    margin: 65px 0 0;
    list-style-type: none;

    li {
        list-style-type: none;

        a {
            &:hover {
                color: inherit;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        margin-top: 10px;

        li {
            padding: 0;
            display: block;
        }
    }
}
