$header-logo-height-md: 70px;
$header-logo-height-xs: $header-logo-height-md/2;

.header {
  @extend .container;
  padding-top: 40px;

  @include media-breakpoint-up(sm) {
    padding-bottom: 30px;
  }

  @include media-breakpoint-down(sm) {
      padding-right: 0
  }
}

.header-logo {
  @include media-breakpoint-up(md) {
    @include make-col(4);
  }

  @include media-breakpoint-up(lg) {
    @include make-col(3);
  }

  @include media-breakpoint-down(sm) {
    margin-left: 15px;
  }

  img {
    height: $header-logo-height-xs;

    @include media-breakpoint-up(md) {
      height: $header-logo-height-md;
    }
  }
}

.header-logo-partner {
  @include media-breakpoint-up(md) {
    // @include make-col(3);
    margin-left: 50px;
  }

  @include media-breakpoint-up(xl) {
    margin-left: 20px;
  }

  @include media-breakpoint-down(sm) {
    margin-left: 20px;
  }

  img {
    height: $header-logo-height-xs;

    @include media-breakpoint-up(md) {
      height: $header-logo-height-md;
    }
  }
}

.header-nav {
  @include media-breakpoint-up(md) {
    @include make-col(9);
    margin-top: 40px;
  }

  @include media-breakpoint-down(md) {
    @include make-col(12);
    margin-top: 0px;
  }

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  a {
    color: inherit;

    &:hover {
      color: darken($text-color, 15%);
    }
  }

  li {
    &.active > a,
    &.current > a {
      font-weight: 500;
      text-transform: uppercase;
    }
  }
}

.header {
  &.it {
    .header-nav {
      @include media-breakpoint-up(md) {
        @include make-col(8);
        margin-top: 40px;
      }
      
      @include media-breakpoint-down(lg) {
        @include make-col(7);
      }
      
      @include media-breakpoint-down(md) {
        @include make-col(12);
      }
    }
  }
}

.header-nav-list,
.header-nav-sub {
  list-style-type: none;
}

.header-nav-list {
  padding: 0;
  margin-bottom: 0;
  @include make-col(12);
  @include media-breakpoint-up(lg) {
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
  }

  @include media-breakpoint-up(lg) {
    text-align: right;

    li {
      font-size: 18px;
      line-height: 1rem;
      display: flex;
      margin-right: 50px;

      a {
          text-transform: uppercase;
      }

      &:last-child {
        margin-right: 0;
      }

      &.current, &.active {
        a {
          color: $brand-primary;
          font-weight: $font-weight-bold;
        }
      }
    }
  }
}

.header-nav-sub {
  @include media-breakpoint-up(md) {
    text-align: right;
  }

  @include media-breakpoint-down(sm) {
    padding-left: 10px;
  }

}

@include media-breakpoint-up(md) {
  .header-nav-sub {
    display: none;
    position: absolute;
    right: 0;
    padding: 0 $grid-gutter-width/2;
    margin: 10px 0;
    font-size: $font-size-small;

    li {
      padding-right: 5px;
    }
  }

  .header-nav-sub {
  }

  .active .header-nav-sub {
    display: block;
  }
}

.header-nav-toggle {
  @include make-col-offset(9);
  position: absolute;
  top: 25px;
  right: 30px;
  z-index: 101;
  color: #000;
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

@media (min-width: $grid-float-breakpoint) {
  .header-nav-content.collapse {
    display: block;
    visibility: visible;
    height: auto;
    overflow: visible;
  }
}

.header-nav-content {
  position: relative;
  @include make-col(12);

  @include media-breakpoint-up(md) {
    margin-top: 0;
  }

  .header-nav-list {
    background-color: $brand-primary;

    li {
      padding: 10px 15px;
      color: $white;
      border-bottom: 1px solid white;
    }

    @include media-breakpoint-up(lg) {
      background-color: transparent;

      li {
        color: $text-color;
        padding: 0;
        border-bottom: none;
      }
    }

    @include media-breakpoint-down(md) {
        margin-top: 30px;
    }
  }
}
