.news-collection--wrapper {

    h3 {
        font-size: 19px;
        color: $color-gray-medium;
        text-transform: uppercase;
        letter-spacing: 0.12em;
        font-weight: $font-weight-normal;
        margin-bottom: 12px;
    }

    .news-teaser--highlight {
        display: flex;
        grid-column-gap: 30px;

        @include media-breakpoint-down(md) {
            flex-wrap: wrap;
            grid-row-gap: 30px;
        }

        img, .content {
            width: 50%;

            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }

        img {
            object-fit: cover;

            @include media-breakpoint-down(lg) {
                height: 100%;
            }
        }


        a {
            margin-top: 12px;
        }
    }

    .news-teaser--overview {
        border-top: 1px solid #707070;
        padding: 25px 0 50px;
    }
}
