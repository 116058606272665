.btn-brand-green {
    @include media-breakpoint-down(sm) {
        padding: 12px 20px;
        line-height: normal;
    }

    background-color: $brand-primary;
    color: $color-white;
    padding: 8px 30px;
    border-radius: 0;
    letter-spacing: 1px;
    font-weight: $font-weight-light;
    font-weight: $font-weight-normal;
    letter-spacing: 0.5px;
    font-size: $font-size-base;
    display: inline-block;
    line-height: normal;
    height: calc(1.5625em + 0.75rem + 2px);
    min-width: 210px;
    text-align: center;
    border-radius: 5px;

    &:hover {
        text-decoration: none;
        background-color: lighten($brand-primary, 4%);
        color: $white;
    }
}

.btn-brand-white {
    @extend .btn-brand-green;
    background-color: transparent;
    border: 1px solid $brand-primary;
    color: $brand-primary;
    text-transform: capitalize;

    &:hover {
        text-decoration: none;
        background-color: $brand-primary;
        color: $white;
    }
}

.btn-brand-green,
.btn-brand-white {
    &.inline-block {
        @include media-breakpoint-down(sm) {
            display: block;
            margin-bottom: 20px;
            margin-right: 0;
            text-align: center;
        }

        display: inline-block;
        height: 44px;
        margin-right: 25px;
        padding: 9px 30px;

        &.mt25 {
            margin-top: 25px;
        }

        &.mt50 {
            margin-top: 50px;
        }

        &.mb25 {
            margin-bottom: 25px;
        }

        &.mb50 {
            margin-bottom: 50px;
        }
    }
}

.button-brand-ghost {
    @extend .btn-brand-green;
    background-color: transparent;
    border: 1px solid $color-gray-medium;
    color: $color-gray-medium;

    &:hover {
        border: none;
    }
}
