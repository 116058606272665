.nav-pills {
    padding-left: 0 !important;
    list-style: none;
    margin-bottom: 0;

    &.items-one {
        li {
            grid-template-columns: repeat(1, 201px);
        }
    }

    &.items-two {
        li {
            grid-template-columns: repeat(2, 201px);
        }
    }

    &.items-three {
        li {
            grid-template-columns: repeat(3, 201px);
            
            @include media-breakpoint-down(md) {
                grid-template-columns: repeat(2, 33%);
                grid-row-gap: 30px;
                place-items: center;
            }
    
            @include media-breakpoint-down(sm) {
                grid-template-columns: repeat(2, 46%);
                grid-row-gap: 15px;
                grid-column-gap: 15px;
            }
        }
    }

    &.items-four {
        li {
            grid-template-columns: repeat(4, 201px);
            
            @include media-breakpoint-down(md) {
                grid-template-columns: repeat(2, 33%);
                grid-row-gap: 30px;
                place-items: center;
            }
    
            @include media-breakpoint-down(sm) {
                grid-template-columns: repeat(2, 46%);
                grid-row-gap: 15px;
                grid-column-gap: 15px;
            }
        }
    }

    &.items-more {
        li {
            grid-template-columns: repeat(5, 201px);

            @include media-breakpoint-down(lg) {
                grid-template-columns: repeat(5, 177px);
                grid-column-gap: 15px;
            }

            @include media-breakpoint-down(md) {
                grid-template-columns: repeat(2, 1fr);
                grid-column-gap: 30px;

                .nav-link {
                    width: 100%;
                }
            }

            @include media-breakpoint-down(sm) {
                grid-row-gap: 15px;
                grid-column-gap: 15px;
            }
        }


    }

    li {
        list-style: none;
        display: grid;
        justify-content: center;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 30px;

        @include media-breakpoint-down(md) {
            grid-template-columns: repeat(2, 33%);
            grid-row-gap: 30px;
            place-items: center;
        }

        @include media-breakpoint-down(sm) {
            grid-template-columns: repeat(2, 46%);
            grid-row-gap: 15px;
            grid-column-gap: 15px;
        }
    }

    .nav-link {
        position: relative;
        width: 100%;
        background-color: $color-petrol-dark;
        border: none;
        z-index: 99;
        padding: 45px 20px 65px;

        @include media-breakpoint-down(md) {
            width: 100%;
            padding: 35px 15px;
        }

        &.woImage {
            padding: 28px 20px;

            h3 {
                text-transform: uppercase;
                letter-spacing: 0.04rem;
                font-size: 25px;

                @include media-breakpoint-down(sm) {
                    font-size: 20px;
                }
            }
        }

        img {
            height: 70px;
            justify-content: center;
            display: flex;
            margin: auto;
            margin-bottom: 16px;

            @include media-breakpoint-down(md) {
                height: 35px;
            }
        }

        h3 {
            font-weight: $font-weight-normal;
            text-align: center;
            color: $white;
            margin-bottom: 0;

            @include media-breakpoint-down(md) {
                min-height: 55px;
            }

            @include media-breakpoint-down(sm) {
                font-size: 20px;
                min-height: 45px;
            }
        }

        &:hover {
            background-color: $color-green-dark;
        }

        &.active {
            background-color: unset;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: calc(100% + 32px);
                width: 100%;
                background-color:  $color-green-dark;
                box-shadow: 5px 5px 5px rgba($color: #000000, $alpha: 0.16);
                z-index: -1;

                @include media-breakpoint-down(md) {
                    height: 100%;
                    box-shadow: none;
                }
            }
        }
    }
}
