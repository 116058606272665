//
// Bootstrap
//
// http://getbootstrap.com/customize/#less-variables
// https://github.com/twbs/bootstrap-sass/blob/v3.3.1/assets/stylesheets/bootstrap/_variables.scss
//

// Grid
$grid-gutter-width: 30px;
$container-large-desktop: (1140px + $grid-gutter-width);
$grid-float-breakpoint: 992px;

// Fonts
$font-family-sans-serif: 'schnebel-sans-pro-condensed', "Helvetica Neue", Arial, sans-serif;
$font-size-base: 1.25rem;
$font-size-small: 0.8rem;
$font-size-h1: 37px;
$font-size-h2: $font-size-h1;
$font-size-h3: 27px;
$font-size-h4: $font-size-h3;
$font-size-h5: $font-size-base;
$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$line-height-base: 1.5625;
$headings-line-height: 1.1;
$headings-font-weight: $font-weight-bold;

// Colors
$color-white: #ffffff;
$color-black: #262626;
$color-petrol-dark: #506e68;
$color-green-dark: #406761;
$color-petrol: #647e78;
$color-petrol-light: #ecefee;
$color-green: #008a3e;
$color-gray: #eeeeee;
$color-gray-medium: #706f6f;
$color-gray-light: #f7f6f5;
$brand-primary: $color-petrol;
$brand-secondary: $color-green;
$text-color: $color-black;
$link-color: $brand-primary;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: none;
$callout-caption-bgcolor: transparentize($color-white, 0.7);

// Components
$border-radius-base: 0;
$well-border: transparent;
$well-bg: $brand-secondary;
$well-color: $color-white;
$input-border-focus: $brand-primary;
