.languages {
  position: absolute;
  z-index: 30;

  @include media-breakpoint-down(sm) {
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
  }

  @include media-breakpoint-up(md) {
    position: fixed;
    top: 10px;
    right: 10px;
  }

  @include media-breakpoint-up(lg) {
    right: 30px;
  }
}

.languages-list {
  @extend .list-inline;

  a {
    @include media-breakpoint-up(sm) {
      color: $text-color;
    }
  }

  .current a {
    font-weight: 500;
  }
}
