.callout {
  margin: 0 auto !important;

  @include media-breakpoint-down(xs) {
    display: none;
  }

  .carousel-item {
    height: 550px;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: $brand-secondary;

    @include media-breakpoint-down(md) {
        height: 356px;
    }
  }
}

.callout-caption {
  @extend .container;
  height: 100%;
}

.callout-caption-box {
  display: none;
  position: absolute;
  padding: 20px;
  color: $color-white;

  @include media-breakpoint-up(sm) {
    display: block;
    left: 8%;
    top: 20%;
    width: 14%;
  }

  @include media-breakpoint-down(lg) {
      width: 33%;
  }

  @media (max-width: 1200px) {
      width: 40%;
  }

  h3 {
    font-weight: $font-weight-normal;
    font-size: $font-size-h3;
    color: $color-white;
    line-height: 40px;
  }
}
