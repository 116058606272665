.breadcrumb--wrapper {
    @extend .container;
    margin-top: 35px;

    .breadcrumb {
        display: flex;
        list-style: none;
        padding-left: 0;
        display: block;
        word-break: break-word;
        hyphens: auto;

        li {
            list-style: none;
            padding-right: 24px;
            position: relative;
            display: contents;

            a {
                color: $color-gray-medium;
            }

            &.current {
               a {
                color: black;
               }
            }

            &::after {
                content: "\276F";
                position: relative;
                top: 0;
                right: 0;
                padding: 0 8px;
                font-size: 18px;
                color: $color-gray-medium;
                display: inline-block;
            }

            &:last-child {
                &::after {
                    content: none;
                }
            }
        }
    }
}
