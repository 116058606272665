.form-group {
    input {
        height: calc(1.5625em + 0.75rem + 2px);
    }

    input,
    textarea {
        font-weight: $font-weight-light;
        background-color: transparent;
        color: white;
        border: 1px solid white;
        border-radius: 0;
        padding: 0 5px;
        width: 100%;

        &::placeholder {
            color: white;
        }
    }
}

.contact-message {
    textarea {
        min-height: 168px;
    }
}

.contact-dsgvo {
    display: flex;
    flex-wrap: nowrap;

    .add-on {
        align-items: baseline;

        @include media-breakpoint-down(sm) {
            min-width: 20px;
        }

        input {
            width: 22px !important;
            margin-top: 6px;

            @include media-breakpoint-down(sm) {
                width: 20px !important;
                height: 20px;
            }
        }
    }

    a {
        color: $color-white;
        text-decoration: underline;
    }
}

.contact-submit {
    ul {
        padding-left: 0 !important;

        li {
            list-style-type: none;
        }
    }
    @include media-breakpoint-up(md) {
        @include make-col(3);
    }
    margin-top: 15px;
}

.contact-confirmation,
.contact-error {
    display: none;
    border: 0;
    border-radius: 0;

    &.alert-success {
        background-color: $brand-primary;
        color: $color-white;
    }

    b {
        font-weight: bold;
    }

    .debug {
        font-size: $font-size-small;
    }
}

.form-control {
    border: 1px solid $color-white;
    border-radius: 0;
    background-color: transparent;
    font-weight: $font-weight-light;
    color: $color-white;

    &::-webkit-input-placeholder {
        /* Edge */
        color: $color-white;
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $color-white;
    }

    &::placeholder {
        color: $color-white;
    }
}

.btn-form.btn-brand-primary {
    border: 1px solid $color-white;
    color: $color-white;
}

.form-columns {
    .col-sm-6 {
        input {
            width: 100%;
            margin-right: 0;
        }

        .col-sm-6 {
            padding-right: 0;

            @include media-breakpoint-down(sm) {
                padding-right: 15px;
            }
        }
    }
}

fieldset {
    legend {
        font-size: $font-size-base;
        font-weight: $font-weight-light;
        color: white;
    }

    .add-on {
        display: flex;
        align-items: center;

        input {
            width: 18px !important;
            height: 18px;
            margin-right: 10px !important;
            border: 1px solid white;

            @include media-breakpoint-down(sm) {
                width: 20px !important;
                height: 20px;
            }
        }

        p {
            margin-bottom: 0;
            font-weight: $font-weight-light;
        }
    }
}

.required {
    font-size: 16px;
    font-weight: $font-weight-light;
}

.actions {
    margin-top: 28px;
}
